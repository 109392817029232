import {
  ConcersaoPacotesConsultor,
  Consultores,
  Grupos,
  ConversaoPacotes,
  DataFinal,
  Faturamento,
  FaturamentoComparacao,
  FaturamentoConcessionaria,
  FaturamentoProdutos,
  FaturamentoRevisao,
  RevisaoComparacao,
  TicketMedioConcessionaria,
  TicketMedioConsultor,
  TipoPacotes,
  Marcas,
  getRetornoFiltro,
} from "@/models/BusinessInteligence";
import ApiService from "./ApiService";

export const faturamento = async (postData): Promise<Faturamento> => {
  const { data } = await ApiService.post(
    `/business-inteligence/faturamento`,
    postData
  );
  return data;
};

export const faturamentoPorConcessionaria = async (
  postData
): Promise<FaturamentoConcessionaria[]> => {
  const { data } = await ApiService.post(
    `/business-inteligence/faturamento_concessionarias`,
    postData
  );
  return data;
};

export const conversaoPacotes = async (
  postData
): Promise<ConversaoPacotes[]> => {
  const { data } = await ApiService.post(
    `/business-inteligence/conversao_pacotes`,
    postData
  );
  return data;
};

export const faturamentoProdutos = async (
  postData
): Promise<FaturamentoProdutos[]> => {
  const { data } = await ApiService.post(
    `/business-inteligence/faturamento_produtos`,
    postData
  );
  return data;
};

export const conversaoPacotesConsultor = async (
  postData
): Promise<ConcersaoPacotesConsultor[]> => {
  const { data } = await ApiService.post(
    `/business-inteligence/conversao_pacotes_consultor`,
    postData
  );
  return data;
};

export const ticketMedio = async (
  postData
): Promise<TicketMedioConcessionaria[]> => {
  const { data } = await ApiService.post(
    `/business-inteligence/ticket-medio`,
    postData
  );
  return data;
};

export const ticketMedioConsultor = async (
  postData
): Promise<TicketMedioConsultor[]> => {
  const { data } = await ApiService.post(
    `/business-inteligence/ticket_medio_consultor`,
    postData
  );
  return data;
};

export const tiposPacotes = async (postData): Promise<TipoPacotes[]> => {
  const { data } = await ApiService.post(
    `/business-inteligence/tipos_pacotes`,
    postData
  );
  return data;
};

export const faturamentoComparacao = async (
  postData
): Promise<FaturamentoComparacao[]> => {
  const { data } = await ApiService.post(
    `/business-inteligence/faturamento_comparacao`,
    postData
  );
  return data;
};

export const revisaoComparacao = async (
  postData
): Promise<RevisaoComparacao[]> => {
  const { data } = await ApiService.post(
    `/business-inteligence/revisao_comparacao`,
    postData
  );
  return data;
};

export const comparacaoFaturamentoRevisao = async (
  postData
): Promise<FaturamentoRevisao[]> => {
  const { data } = await ApiService.post(
    `/business-inteligence/faturamento_revisoes`,
    postData
  );
  return data;
};

export const consultores = async (): Promise<Consultores[]> => {
  const { data } = await ApiService.get(`/business-inteligence/consultores`);
  return data;
};

export const grupos = async (postData): Promise<Grupos[]> => {
  const { data } = await ApiService.post(
    `/business-inteligence/grupos`,
    postData
  );
  return data;
};

export const marcas = async (postData): Promise<Marcas[]> => {
  const { data } = await ApiService.post(
    `/business-inteligence/marcas`,
    postData
  );
  return data;
};

export const dataFinalLancamento = async (postData): Promise<DataFinal> => {
  const { data } = await ApiService.post(
    `/business-inteligence/dataFinal`,
    postData
  );
  return data;
};

export const retornoFiltro = async (postData): Promise<getRetornoFiltro[]> => {
  const { data } = await ApiService.post(
    `/business-inteligence/retornoFiltro`,
    postData
  );
  return data;
};

export default {
  faturamento,
  faturamentoPorConcessionaria,
  conversaoPacotes,
  faturamentoProdutos,
  conversaoPacotesConsultor,
  ticketMedio,
  grupos,
  marcas,
  ticketMedioConsultor,
  tiposPacotes,
  faturamentoComparacao,
  revisaoComparacao,
  comparacaoFaturamentoRevisao,
  consultores,
  dataFinalLancamento,
  retornoFiltro,
};
